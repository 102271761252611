import { CustomerData, CustomerWallet } from "../CustomerModel";
import { TransactionData, TransactionType } from "../TransactionDataModel"

const BASE_URL_JAVA = "https://backend.narahidairy.com:8445"
// const BASE_URL_JAVA = "http://localhost:8081"

export async function fetchCustomerInfo(organizationId: string, tokenPromise: Promise<string>): Promise<CustomerData[]> {

  return tokenPromise.then(async (token) => {
    return fetch(`${BASE_URL_JAVA}/customers/info`, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + await token,
        'X-Org-Id': organizationId,
      }
    }
    ).then(async (result) => {
      const values = (await result.json()) as Array<CustomerData>
      return values
    });
  });
}

export async function fetchUserWalletDetail(customerId: string, orgId: string, token: string): Promise<CustomerWallet> {
  const searchParam = new URLSearchParams()
  searchParam.append("customerId", customerId)
  searchParam.append("orgId", orgId)
  return fetch(`${BASE_URL_JAVA}/customers/wallet?${searchParam.toString()}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token,
      'X-Org-Id': orgId,
      'X-User-Id': customerId,
    },
    body: JSON.stringify({
      customerId: customerId,
      orgId: orgId
    })
  }
  ).then(async (result) => {
    const values = (await result.json()) as CustomerWallet
    return values
  });
}

export async function fetchCustomerTxnBetween(customerId: string, orgId: string, token: string): Promise<Array<TransactionData>> {
  return fetch(`${BASE_URL_JAVA}/transactions/unBilledTransactions`, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + token,
      'X-Org-Id': orgId,
      'X-User-Id': customerId,
    }
  }
  ).then(async (result) => {
    const values = (await result.json()) as Array<TransactionData>
    return values
  });
}



