import React from "react"

export const getDisplayType = () => {
    const width: number = window.innerWidth;
    if(width > 1080) {
        return 'Desktop';
    }

    if(width > 600) {
        return 'Tablet';
    }

    return 'Mobile';
}

export const getShortDateTimeString = (time: number) => {
    return new Date(time).toLocaleString("en-IN", { dateStyle: "short", timeStyle: "short" })
}
