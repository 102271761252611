export class TransactionData {
    id: string;
    time: number;
    customer_id: string;
    item_id: string;
    agent_id: string;
    location: string;
    GeoCoordinate: string;
    quantity: number;
    rate: number;
    txn_type: string;
    is_cash: boolean;
    cash_amount: number;
    balance_after_txn: number;
    balance_update: string;
    balance_update_time: number;
    is_uploaded: boolean;
    is_reverted: boolean;
    reverted_balance: number;
    attributes: string;

    constructor(data: any) {
        this.id = data.id || '';
        this.time = data.time || 0;
        this.customer_id = data.customer_id || '';
        this.item_id = data.item_id || '';
        this.agent_id = data.agent_id || '';
        this.location = data.location || '';
        this.GeoCoordinate = data.GeoCoordinate || '';
        this.quantity = data.quantity || 0;
        this.rate = data.rate || 0;
        this.txn_type = data.txn_type || '';
        this.is_cash = data.is_cash || false;
        this.cash_amount = data.cash_amount || 0;
        this.balance_after_txn = data.balance_after_txn || 0;
        this.balance_update = data.balance_update || '';
        this.balance_update_time = data.balance_update_time || 0;
        this.is_uploaded = data.is_uploaded || false;
        this.is_reverted = data.is_reverted || false;
        this.reverted_balance = data.reverted_balance || 0;
        this.attributes = data.attributes || '';
    }

    
}

export class TransactionDataWithBalance extends TransactionData {
    updatedBalance: number | undefined = undefined
    amount: number  = this.rate * this.quantity
}

export const getAmount = (txn: TransactionData) => {
    const txnAMt = txn.rate * txn.quantity
    const txnAmount = Math.ceil(txnAMt*100) /100
    if (!txn.is_cash) {
        if (txn.txn_type === TransactionType.COLLECTION) {
            return txnAmount
        } else {
            return -1 * txnAmount
        }
    } else return 0.0
}

export const populateBalance = (txn: TransactionData, currentBalance: number) => {
    const amt = getAmount(txn)
    const transactionDataModel = {
        updatedBalance: currentBalance + amt,
        amount: amt,
        ...txn
    }
    return transactionDataModel
}


export enum TransactionType {
    COLLECTION = "COLLECTION",
    PAYMENT = "PAYMENT",
    DAIRY_CARE = "DAIRY_CARE",
    DAIRY_PRODUCT_SELL = "DAIRY_PRODUCT_SELL"
}