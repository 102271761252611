
export const messages = {
    "en": {
        hello: "Hello",
        ji: "Ji",
        signin_alreadyLoggedInMesage: "User Already LoggedIn",
        signin_enterPhone: "Enter your Phone Number",
        userData_lastBill: "Last Billing",
        userData_customerAccount: "Account Number",
        signin_sendOTP: "Send One Time Password",
        signin_enterOtpLabel: "Enter OTP Received On Above Mobile",
        signin_enterOtp: "Enter OTP",
        signin_loading_account: "Loading your account info"
    },
    "hi": {
        hello: "नमस्कार",
        ji: "जी",
        signin_enterPhone: "अपना फोन नंबर डालें",
        userData_lastBill: "पिछला हिसाब",
        userData_lastBill_amount: "पिछला हिसाब राशि",
        userData_customerAccount: "खाता संख्या",
        signin_sendOTP: "OTP पासवर्ड भेजे",
        signin_enterOtpLabel: "ऊपर दिये गये मोबाइल पर प्राप्त ओट्स दर्ज करें",
        signin_enterOtp: "OTP डालें",
        signin_loading_account: "आपके खाते की जानकारी लोड हो रही है"

    }
}