import { useContext, useEffect, useMemo, useState } from "react";
import { fetchCustomerTxnBetween, fetchUserWalletDetail } from "../../common/utils/FetchUtils";
import { CustomerContext } from "./customerContext";
import { Alert, Card, ListGroup, Spinner, Table } from "react-bootstrap";
import { CustomerWallet } from "../../common/CustomerModel";
import { TransactionData, TransactionDataWithBalance, getAmount, populateBalance } from "../../common/TransactionDataModel";
import { getShortDateTimeString } from "../../common/utils/utils";
import TableContainer from "../../components/tableContainer/TableContainer";
import { FormattedMessage } from "react-intl";
import { auth } from "../../common/config/firebase/FirebaseConfig";
import { UserContext } from "../signin/userContext";

export type UserDataProps = {
    organizationId: string
}

const UserData = ({ organizationId }: UserDataProps) => {

    const { customer } = useContext(CustomerContext)!!
    const { user } = useContext(UserContext)!!
    const [wallet, setWallet] = useState<CustomerWallet | undefined>(undefined)
    const [walletLoading, setWalletLoading] = useState<Boolean>(true)
    const [transactionsLoading, setTransactionsLoading] = useState<Boolean>(true)
    const [transactions, setTransactions] = useState<Array<TransactionData>>()
    const [transactionsWithBalance, setTransactionsWithBalance] = useState<Array<TransactionData>>()
    const [currentBalance, setCurrentBalance] = useState<number | undefined>(undefined)
    let calculatedBalance: number | undefined = undefined

    const columnsData = [
        {
            Header: "Time",
            size: 200,
            accessor: (value: TransactionData) => {
                const date = new Date(value.time);
                const day = new Intl.DateTimeFormat("en-IN", {
                    day: "2-digit",
                    month: "short"
                }).format(value.time)

                const time = new Intl.DateTimeFormat("en-IN", {
                    hour: "2-digit",
                    minute: "2-digit"
                }).format(value.time)
                return `${day} ${time}`;
            },
            disableFilters: true
        },
        {
            Header: "Item",
            accessor: (value: TransactionDataWithBalance) => {
                        switch (value.item_id) {
                            case "cow_milk_ballia": 
                            case "cow_milk_narahi": 
                                return "Cow Milk"
                            default: return value.item_id.toUpperCase().replace("_", " ")
                        }
                    },
            disableFilters: true
        },
        {
            Header: "Quantity",
            accessor: "quantity",
            disableFilters: true
        },
        {
            Header: "Rate",
            accessor: (value: TransactionDataWithBalance) => {
                switch (value.txn_type) {
                    case "COLLECTION":
                        return value.rate + " @ " + value.attributes
                    default: return value.rate
                }
            },
            disableFilters: true
        },
        // {
        //     Header: "Amount",
        //     accessor: (value: TransactionDataWithBalance) => {
        //         return `${value.amount}`
        //     },
        //     Cell: (props: any) => {
        //         return (
        //             <p style={{ color: props.value > 0 ? 'green' : 'red' }}>{props.value}</p>
        //         );
        //     },
        //     disableFilters: true,
        //     enableHiding: true
        // },
        // {
        //     Header: "Balance",
        //     accessor: (value: TransactionDataWithBalance) => {
        //         return `${value.updatedBalance}`
        //     },
        //     getProps: (state: any, rowInfo: any, column: any) => {
        //         return {
        //             style: {
        //                 color: rowInfo && rowInfo.row.updatedBalance > 0 ? 'green' : 'red',
        //             },
        //         };
        //     },
        //     Cell: (props: any) => {
        //         return (
        //             <p style={{ color: props.value > 0 ? 'green' : 'red' }}>{props.value}</p>
        //         );
        //     },
        //     disableFilters: true
        // },
    ];
    const columns = useMemo(() => columnsData, [])

    useEffect(() => {
        if (customer) {
            auth.currentUser?.getIdToken().then(token => {
                fetchUserWalletDetail(customer?.id, organizationId, token).then(w => {
                    setWallet(w);
                    calculatedBalance = w.balance;
                    setWalletLoading(false);
                })
                    .catch((error: any) => {
                        console.error(error)
                        setWalletLoading(true)
                    })
            })
        }
    }, [customer])

    useEffect(() => {
        if (customer && wallet !== undefined) {
            user?.getIdToken().then(token => {
                fetchCustomerTxnBetween(customer?.id, organizationId, token)
                    .then((transactions) => {
                        setTransactions(transactions)
                        setTransactionsLoading(false)
                    })
            })
        }
    }, [wallet])

    useEffect(() => {
        if (wallet && transactions) {
            calculatedBalance = wallet.balance
            let transactionsWithBalance: Array<TransactionDataWithBalance> = transactions.sort((a, b) => a.time - b.time)
                .map((t) => {
                    const bal: number = (calculatedBalance === undefined) ? wallet.balance : calculatedBalance
                    const transactionDataWithBalance = populateBalance(t, bal);
                    calculatedBalance = Math.round(100*transactionDataWithBalance.updatedBalance)/100
                    return transactionDataWithBalance

                })
            setTransactionsWithBalance(transactionsWithBalance)
            setCurrentBalance(calculatedBalance)
        }
    }, [transactions])



    function getBalanceCalculated(txn: TransactionData): number | undefined {
        const amt = getAmount(txn)
        if (calculatedBalance) {
            calculatedBalance = calculatedBalance + amt
        }
        else if (wallet) {
            calculatedBalance = wallet.balance + amt
        }
        calculatedBalance = calculatedBalance ? Math.round(calculatedBalance * 100) / 100 : calculatedBalance
        return calculatedBalance
    }

    function getNumericTextColor(num: number | undefined): string {
        return num ? (num < 0 ? "text-danger" : "text-success") : "text-warning"
    }

   

    if (wallet) {
        //reseting calculated balance on each rerendering
        wallet.balance = Math.round(100 * wallet.balance)/100
        calculatedBalance = wallet.balance
    }


    return <>
        {
            customer && wallet ?
                <>
                    <div className="d-flex justify-content-center">
                        <Card style={{ width: '18rem' }}>
                            <Card.Body>
                                <Card.Title><FormattedMessage id="hello" />, {customer.name} <FormattedMessage id="ji" /> </Card.Title>
                                <Card.Subtitle className="mb-2 text-muted"><FormattedMessage id="userData_customerAccount" />: {customer.id}</Card.Subtitle>
                                <ListGroup variant="flush">
                                    <ListGroup.Item><FormattedMessage id="userData_lastBill" />: {getShortDateTimeString(wallet.receiptTillDate)}</ListGroup.Item>
                                    <ListGroup.Item className={getNumericTextColor(wallet.balance)}> <FormattedMessage id="userData_lastBill_amount" />: {wallet.balance}</ListGroup.Item>
                                </ListGroup>
                                {/* <Card.Link href="/lastBill">LastBill</Card.Link> */}
                            </Card.Body>
                        </Card>
                    </div>

                    {transactionsWithBalance ? <>
                        <div className="d-flex justify-content-center">
                            <h3>Current Balance: {currentBalance}</h3>
                        </div>
                        <TableContainer columns={columns} data={transactionsWithBalance} />
                    </>
                        : <Spinner animation="border" variant="primary" />
                    }
                </>
                : <>
                    {
                        walletLoading ?
                            <div>
                                <Spinner animation="border" variant="primary" />
                            </div>
                            : <Alert variant="danger">No valid customer for wallet</Alert>

                    }
                </>
        }
    </>
}



export default UserData